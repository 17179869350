import { Flex, Text, Tr, Table, Tbody, Th, Thead, Heading, Td } from '@chakra-ui/react'
import { useState } from 'react'
import { GetReportChangesQuery } from '../../../generated/graphql'
import { useIsEmployeeView } from '../../../hooks/id_token_claims'
import { isInternalStateChangeValue } from '../../shared/change_history'
import DropdownSelector from '../../shared/dropdown_selector'
import { AuditLog } from './audit_log'
import { ENFORCEMENT_STATUS } from '../../../utils/constants'
import {
  CollectionReportChangeTypeLabels,
  CollectionReportChangeType,
} from '@/generated/enums'

const AUDIT_LOGS_PREVIEW_HEIGHT = '300px' // Set a fixed height for the logs container
const INTERNAL_AUDIT_LOG_TYPES = [
  CollectionReportChangeType.SCORE,
  CollectionReportChangeType.IMPORTANCE_SCORE,
  CollectionReportChangeType.MAX_SIMILARITY_SCORE,
  CollectionReportChangeType.ADD_LINK,
  CollectionReportChangeType.REMOVE_LINK,
]

const INTERNAL_AUDIT_LOG_VALUES = [ENFORCEMENT_STATUS.STAGED as string]

export function AuditLogTable({
  stateChanges,
  showHeader = true,
}: {
  stateChanges: GetReportChangesQuery
  showHeader?: boolean
}) {
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedLogType, setSelectedLogType] = useState('')

  const { collection_report_changes: auditLogs } = stateChanges

  const uniqueReportChangeTypes = Array.from(
    new Set(auditLogs.map((change) => CollectionReportChangeTypeLabels[change.type])),
  )

  const roleFilteredLogs = auditLogs.filter((auditLog) => {
    if (isEmployeeView) {
      return true
    }

    return (
      !isInternalStateChangeValue(auditLog.new_value) &&
      !INTERNAL_AUDIT_LOG_TYPES.includes(auditLog.type as CollectionReportChangeType) &&
      !INTERNAL_AUDIT_LOG_VALUES.includes(auditLog.new_value)
    )
  })

  const logTypeFilteredLogs = roleFilteredLogs.filter((auditLog) => {
    return selectedLogType
      ? CollectionReportChangeTypeLabels[auditLog.type] === selectedLogType
      : true
  })

  return (
    <Flex direction="column" width="100%">
      <Flex alignItems="center" marginBottom={2}>
        {showHeader && (
          <Heading as="h4" mr={2} size="md">
            Audit Logs
          </Heading>
        )}

        <DropdownSelector
          items={uniqueReportChangeTypes}
          mr={2}
          onItemSelected={(value) => setSelectedLogType(value)}
          placeholder="All"
          selectedItem={selectedLogType}
          size="xs"
          width="170px"
        />
      </Flex>

      <Flex direction="column" maxHeight={AUDIT_LOGS_PREVIEW_HEIGHT} overflowY="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th minWidth="165px">Date</Th>

              <Th>Log Type</Th>

              <Th>Value</Th>

              <Th>Metadata</Th>

              <Th>Changed By</Th>
            </Tr>
          </Thead>

          <Tbody>
            {logTypeFilteredLogs.length == 0 && (
              <Tr>
                <Td>
                  <Text fontSize={13}>No changes recorded</Text>
                </Td>
              </Tr>
            )}

            {logTypeFilteredLogs.map((auditLog) => (
              <AuditLog auditLog={auditLog} key={`state-change-${auditLog.id}`} />
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  )
}
