import React from 'react'
import { Tr, Td, Text, Tooltip, Flex } from '@chakra-ui/react'
import { toSnakeCase } from '@/utils/string_utils'
import { DOPPEL_SECURE } from '@/utils/style'
import { getTimestampDisplay } from '@/utils/time'
import { ENFORCEMENT_TYPE_TO_ICON } from '@/components/enforcements/enforcement_statuses'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import { getExternalUser } from '@/utils/reports/report_utils'
import {
  CollectionReportChangeTypeLabels,
  ReportStatusLabels,
  CollectionReportChangeType,
  ReportChangeEventSource,
} from '@/generated/enums'

export function AuditLog({ auditLog }) {
  const [isEmployeeView] = useIsEmployeeView()

  return (
    <Tr>
      <Td>
        <Text as="span" color={DOPPEL_SECURE} fontSize={12}>
          {getTimestampDisplay(auditLog.timestamp)}
        </Text>
      </Td>

      <Td>
        <Text as="span" fontSize={12}>
          {CollectionReportChangeTypeLabels[auditLog.type]}
        </Text>
      </Td>

      <Td>
        <Text as="span" fontSize={12} noOfLines={3}>
          {renderAuditValue(auditLog)}
        </Text>
      </Td>

      <Td>{renderMetadata(auditLog)}</Td>

      <Td>
        <Text as="span" fontSize={12}>
          {renderChangedBy(auditLog, isEmployeeView)}
        </Text>
      </Td>
    </Tr>
  )
}

function renderAuditValue(auditLog) {
  let newValue = ''
  switch (auditLog.type) {
    case CollectionReportChangeType.REPORT_STATUS:
      newValue = ReportStatusLabels[auditLog.new_value]
      break
    case CollectionReportChangeType.PLATFORM_SUBTYPE:
      // TODO(DOP-5021): get the name instead of just the id
      newValue = auditLog.new_value
      break
    case CollectionReportChangeType.NOTES:
      newValue = auditLog.new_value
      break
    default:
      newValue = auditLog.new_value
  }

  return <Text fontSize={12}>{newValue}</Text>
}

function getExternalSource(source: ReportChangeEventSource, isEmployeeView: boolean) {
  if (
    !isEmployeeView &&
    ![ReportChangeEventSource.UI, ReportChangeEventSource.BULK_UI].includes(source)
  ) {
    return 'Doppel' // needs to be consistent with getExternalUser
  }
  return source
}

function renderChangedBy(auditLog, isEmployeeView) {
  if (auditLog.user) {
    return getExternalUser(auditLog.user, isEmployeeView)
  }
  return getExternalSource(auditLog.source, isEmployeeView)
}

export function renderMetadata(auditLog) {
  if (auditLog.source === ReportChangeEventSource.IR_TO_DR_RULE) {
    return (
      <Text fontSize={12} ml={2}>
        {'DetectionAsset type: ' + auditLog.extra_data?.detection_asset_type}
      </Text>
    )
  }

  if (
    [
      CollectionReportChangeType.ENFORCEMENT_REQUEST_STATUS,
      CollectionReportChangeType.ENFORCEMENT_EMAIL_STATUS,
    ].includes(auditLog.type)
  ) {
    if (!auditLog.enforcement_request) return <></>

    return (
      <Flex alignItems="center">
        <Tooltip label={auditLog.enforcement_request.type}>
          <span>{ENFORCEMENT_TYPE_TO_ICON[auditLog.enforcement_request.type]}</span>
        </Tooltip>

        <Text fontSize={12} ml={2}>
          {toSnakeCase(auditLog.enforcement_request.platform)}
        </Text>
      </Flex>
    )
  }

  return <Text fontSize={12}>{toSnakeCase(auditLog?.newValue || '')}</Text>
}
